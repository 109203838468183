import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './layout/home/home.component';
import { WorkInProgressComponent } from './work-in-progress/work-in-progress.component';
import { AboutComponent } from './layout/about/about.component';
import { HobbiesComponent } from './layout/hobbies/hobbies.component';
import { WorkComponent } from './layout/work/work.component';
import { MedComponent } from './layout/work/med/med.component';
import { CraftComponent } from './layout/work/craft/craft.component'
import { CakeComponent } from './layout/work/cake/cake.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'wip',
    component: WorkInProgressComponent
  },
  {
    path: '',
    component: WorkComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'hobbies',
    component: HobbiesComponent
  },
  {
    path: 'med',
    component: MedComponent
  },
  {
    path: 'paris-crafts',
    component: CraftComponent
  },
  {
    path: 'the-cake-alchemy',
    component: CakeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
