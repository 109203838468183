import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public menuSelected:number = 1;
  constructor(
    private router: Router
  ) {
    router.events.subscribe((event:any) => {
      if(event.url){
        const url:string = event.url.toString();
        switch(url) {
          case '/':
            this.menuSelected = 1;
            break;
          case '/about':
            this.menuSelected = 2;
            break;
          case '/hobbies':
            this.menuSelected = 4;
            break;
          default:
            this.menuSelected = 1;
        }
      }
    });
   }

  ngOnInit() {
  }

  public navigateToPage(url:string, value:number){
    this.menuSelected = value;
    this.router.navigateByUrl(url);
  }
}
