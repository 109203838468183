import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WorkInProgressComponent } from './work-in-progress/work-in-progress.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module/material-module.module';
import { MatCheckboxModule, MatIconModule, MatToolbarModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { HomeLayoutModule } from './layout/home-layout.module';
import { HeaderComponent } from './layout/header/header.component';
import { BrandComponent } from './brand/brand/brand.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    WorkInProgressComponent,
    HeaderComponent,
    BrandComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HomeLayoutModule,
    MatToolbarModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
