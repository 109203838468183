import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-craft',
  templateUrl: './craft.component.html',
  styleUrls: ['./craft.component.scss']
})
export class CraftComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  public navigateToPage(url:string){
    this.router.navigateByUrl(url);
  }

}