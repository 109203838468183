import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeComponent } from './home/home.component';
import { MaterialModule } from '../material-module/material-module.module';
import { AboutComponent } from './about/about.component';

import { HobbiesComponent } from './hobbies/hobbies.component';
import { WorkComponent } from './work/work.component';
import { MedComponent } from './work/med/med.component';
import { CraftComponent } from './work/craft/craft.component';
import { CakeComponent } from './work/cake/cake.component';

@NgModule({
  declarations: [
    HomeComponent,
    //HeaderComponent,
    AboutComponent,
    //BrandComponent,
    HobbiesComponent,
    WorkComponent,
    MedComponent,
    CraftComponent,
    CakeComponent
 ],
  imports: [
    CommonModule,
    MaterialModule
  ]
})
export class HomeLayoutModule { }
